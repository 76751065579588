<template>
  <div class="user-cabinet__row">
    <label class="user-cabinet__label" for="">{{title}}</label>
    <div class="user-cabinet__input">
      <div class="user-cabinet__note">{{subtitle}}</div>
      <slot name="main"></slot>
      <div class="user-cabinet__btn right" v-if="showSaveButton">
        <slot name="button">
          <button type="submit" class="btn btn-primary">Save</button>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    showSaveButton: {
      type: Boolean,
      defailt: false,
    },
  },
};
</script>
