<template>
  <div class="user-cabinet__inner">
    <div class="user-cabinet__form">
      <Alert :variant="variant" v-if="text && successName == 'avatar'">{{ text }}</Alert>
      <div class="user-cabinet__row">
        <label class="user-cabinet__label" for="">Change Avatar</label>
        <div class="user-cabinet__input">
          <div class="user-cabinet__note">Change your avatar by upload a new image</div>
          <ValidationProvider
            name="avatar"
            rules="image|size:3000"
            ref="avatar"
            v-slot="{ errors }"
          >
            <div class="avatarWrapper">
              <img class="avatar" id="avatar" src="@/assets/images/avatar.svg" />
            </div>
            <span class="help-message">{{ errors[0] }}</span>
            <div class="user-cabinet__btn right">
              <label class="btn btn-primary">
                <input type="file" @change="uploadAvatar" accept="image/*"/>
                Upload
              </label>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <Alert :variant="variant" v-if="text && successName == 'password'">{{ text }}</Alert>
      <ValidationObserver slim v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(changePassword)">
          <WrapperProfileTextField
            :showSaveButton="true"
            title="Change Password"
            subtitle="Change your password by entering a new one below"
          >
            <template #main>
              <ValidationProvider
                name="password"
                rules="required|min:8|hardPassword"
                v-slot="{ errors, failed }"
              >
                <div class="form-row" :class="{ 'has-error': failed }">
                  <input
                    name="password"
                    v-model="password"
                    class="form-control"
                    type="password"
                    placeholder="Password"
                  />
                  <span class="help-message">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </template>
          </WrapperProfileTextField>
        </form>
      </ValidationObserver>
      <Alert :variant="variant" v-if="text && successName == 'first_name'">{{ text }}</Alert>
      <ValidationObserver slim v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(changeFirstName)">
          <WrapperProfileTextField
            :showSaveButton="true"
            title="Change First Name"
            subtitle="Change your first name by entering a new one below"
          >
            <template #main>
              <ValidationProvider name="First Name" rules="required" v-slot="{ errors, failed }">
                <div class="form-row" :class="{ 'has-error': failed }">
                  <input
                    name="first_name"
                    v-model="first_name"
                    class="form-control"
                    type="text"
                    placeholder="First Name"
                  />
                  <span class="help-message">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </template>
          </WrapperProfileTextField>
        </form>
      </ValidationObserver>
      <Alert :variant="variant" v-if="text && successName == 'last_name'">{{ text }}</Alert>
      <ValidationObserver slim v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(changeLastName)">
          <WrapperProfileTextField
            :showSaveButton="true"
            title="Change Last Name"
            subtitle="Change your last name by entering a new one below"
          >
            <template #main>
              <ValidationProvider name="Last Name" rules="required" v-slot="{ errors, failed }">
                <div class="form-row" :class="{ 'has-error': failed }">
                  <input
                    name="last_name"
                    v-model="last_name"
                    class="form-control"
                    type="text"
                    placeholder="Last Name"
                  />
                  <span class="help-message">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </template>
          </WrapperProfileTextField>
        </form>
      </ValidationObserver>
    </div>
    <Modal
      @close="hideModal('modalSettings')"
      id="modalSettings"
      title="DeleteAccount"
      buttonTextOk="Delete Account"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal('modalSettings')"
      @ok="onDeleteAcount"
    >
      <p>Your request has been received! You should receive an email about application status.</p>
    </Modal>

    <div class="user-cabinet__footer">
      <span @click="showModal('modalSettings')" class="btn btn-danger">Delete Account</span>
    </div>
  </div>
</template>
<script>
import ProfileTextField from '@/components/wrapper/ProfileTextField.vue';
import Alert from '@/components/Alert.vue';
import Modal from '@/components/Modal.vue';

import UserService from '@/services/UserService';
import AutorizationService from '@/services/AutorizationService';

import alert from '@/mixins/alert';
import modal from '@/mixins/modal';
import autorization from '@/mixins/autorization';
import content from '@/mixins/content';

export default {
  mixins: [alert, modal, autorization, content],
  components: {
    Alert,
    Modal,
    WrapperProfileTextField: ProfileTextField,
  },
  mounted() {
    this.setDefaultDataUSer();
  },
  data() {
    return {
      successName: '',
      password: null,
      first_name: null,
      last_name: null,
      avatar: null,
    };
  },
  methods: {
    async uploadAvatar(input) {
      const { valid } = await this.$refs.avatar.validate(input);

      if (valid) {
        this.preShowImg(input.target.files[0]);
      }
    },

    async preShowImg(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          document.querySelector('#avatar').setAttribute('src', e.target.result);
          this.avatar = e.target.result;
          this.changeAvatar();
        };
        reader.readAsDataURL(file);
      }
    },
    changeAvatar() {
      this.successName = 'avatar';
      this.onUpdate({ avatar: this.avatar });
    },
    changePassword() {
      this.successName = 'password';
      this.onUpdate({ password: this.password });
    },
    changeFirstName() {
      this.successName = 'first_name';
      this.onUpdate({ first_name: this.first_name });
    },
    changeLastName() {
      this.successName = 'last_name';
      this.onUpdate({ last_name: this.last_name });
    },

    setDefaultDataUSer() {
      this.first_name = this.me.first_name;
      this.last_name = this.me.last_name;
      if (this.me.avatar) {
        document.querySelector('#avatar').setAttribute('src', this.me.avatar);
      }
    },

    async onUpdate(val) {
      try {
        this.removeAlert();
        const { data } = await UserService.update(val);
        this.$store.commit('SET_ME', data);
        this.setAlert({ textArg: 'Successfully updated', variantArg: 'success' });
        setTimeout(() => {
          this.removeAlert();
        }, 5000);
      } catch (e) {
        this.setAlert({ textArg: e.response.data[0].message, variantArg: 'danger' });
      }
    },
    onDeleteAcount() {
      Promise.all([UserService.block(), AutorizationService.logout()])
        .then(() => {
          this.removeAutorization();
        })
        .catch((e) => {
          this.setAlert({ textArg: e.response.data[0].message, variantArg: 'danger' });
        });
    },
  },
  computed: {
    me() {
      return this.$store.getters.getMe;
    },
  },
};
</script>
<style lang="scss" scoped>
input[type="file"] {
  display: none;
}
</style>
