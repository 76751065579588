var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-cabinet__inner"},[_c('div',{staticClass:"user-cabinet__form"},[(_vm.text && _vm.successName == 'avatar')?_c('Alert',{attrs:{"variant":_vm.variant}},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_c('div',{staticClass:"user-cabinet__row"},[_c('label',{staticClass:"user-cabinet__label",attrs:{"for":""}},[_vm._v("Change Avatar")]),_c('div',{staticClass:"user-cabinet__input"},[_c('div',{staticClass:"user-cabinet__note"},[_vm._v("Change your avatar by upload a new image")]),_c('ValidationProvider',{ref:"avatar",attrs:{"name":"avatar","rules":"image|size:3000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"avatarWrapper"},[_c('img',{staticClass:"avatar",attrs:{"id":"avatar","src":require("@/assets/images/avatar.svg")}})]),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))]),_c('div',{staticClass:"user-cabinet__btn right"},[_c('label',{staticClass:"btn btn-primary"},[_c('input',{attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.uploadAvatar}}),_vm._v(" Upload ")])])]}}])})],1)]),(_vm.text && _vm.successName == 'password')?_c('Alert',{attrs:{"variant":_vm.variant}},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('WrapperProfileTextField',{attrs:{"showSaveButton":true,"title":"Change Password","subtitle":"Change your password by entering a new one below"},scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8|hardPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",attrs:{"name":"password","type":"password","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]},proxy:true}],null,true)})],1)]}}])}),(_vm.text && _vm.successName == 'first_name')?_c('Alert',{attrs:{"variant":_vm.variant}},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changeFirstName)}}},[_c('WrapperProfileTextField',{attrs:{"showSaveButton":true,"title":"Change First Name","subtitle":"Change your first name by entering a new one below"},scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.first_name),expression:"first_name"}],staticClass:"form-control",attrs:{"name":"first_name","type":"text","placeholder":"First Name"},domProps:{"value":(_vm.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.first_name=$event.target.value}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]},proxy:true}],null,true)})],1)]}}])}),(_vm.text && _vm.successName == 'last_name')?_c('Alert',{attrs:{"variant":_vm.variant}},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changeLastName)}}},[_c('WrapperProfileTextField',{attrs:{"showSaveButton":true,"title":"Change Last Name","subtitle":"Change your last name by entering a new one below"},scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.last_name),expression:"last_name"}],staticClass:"form-control",attrs:{"name":"last_name","type":"text","placeholder":"Last Name"},domProps:{"value":(_vm.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.last_name=$event.target.value}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]},proxy:true}],null,true)})],1)]}}])})],1),_c('Modal',{attrs:{"id":"modalSettings","title":"DeleteAccount","buttonTextOk":"Delete Account","buttonVariantOk":"danger","showButtonCancel":true},on:{"close":function($event){return _vm.hideModal('modalSettings')},"cancel":function($event){return _vm.hideModal('modalSettings')},"ok":_vm.onDeleteAcount}},[_c('p',[_vm._v("Your request has been received! You should receive an email about application status.")])]),_c('div',{staticClass:"user-cabinet__footer"},[_c('span',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.showModal('modalSettings')}}},[_vm._v("Delete Account")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }